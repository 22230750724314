import CompanyLogo from './company_logo.png'
var url = document.location.href + "";
var pathParams = url.split("cwac.co.in/#/ref=");
//var pathParams = (document.location.href + "").split("localhost:3007/#/ref=");
var salesmanid = "";
export const Navigation = (props) => {
  if (url.includes("#about") || url.includes("#services") || url.includes("#projects") || url.includes("#testimonials") || url.includes("#contact"))
  {
    salesmanid = localStorage.getItem("salesman_id");
  }
  else
  {
    localStorage.removeItem("salesman_id");
    if (pathParams.length > 1) salesmanid = pathParams[1].trim();
    if (salesmanid.includes("#")) salesmanid = salesmanid.split("#")[0].trim();
    localStorage.setItem("salesman_id", salesmanid);
  }
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          {/* <a className='navbar-brand page-scroll' href='#page-top'>
            React Landing Page
          </a>{' '} */}
          <img width="150px" height="65px" src={CompanyLogo} />
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <a href={'#about'} className='page-scroll' style={{textTransform: 'none'}}>
                About
              </a>
            </li> */}
            
            <li>
              <a href='#services' className='page-scroll' style={{textTransform: 'none'}}>
                Services
              </a>
            </li>
            {/* <li>
              <a href='#projects' className='page-scroll' style={{textTransform: 'none'}}>
                Projects
              </a>
            </li> */}
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{textTransform: 'none'}}>
                SaaS Products
              </a>
              <div class="dropdown-content">
                <a href={"https://cwac.co.in/autoditor_info/" + (salesmanid ? "ref=" + salesmanid : "")} target="_blank">Autoditor: For Audit Industry</a>
                <a href={"https://cwac.co.in/qualitraceman_info/" + (salesmanid ? "ref=" + salesmanid : "")} onClick={evt => localStorage.setItem("industry", "mech")} target="_blank">Qualitraceman: For Manufacturing Industry</a>
                <a href={"https://cwac.co.in/qualitraceman_info/" + (salesmanid ? "ref=" + salesmanid : "")} onClick={evt => localStorage.setItem("industry", "civil")} target="_blank">Qualitraceman: For Construction Industry</a>
                <a href={"https://cwac.co.in/aureminder_info/" + (salesmanid ? "ref=" + salesmanid : "")} target="_blank">Aureminder: To Manage Auto-reminder for all Industries</a>
              </div>
              {/* <div class="dropdown-content">
                <a href="https://cwac.co.in/qualitraceman_info" target="_blank">Qualitraceman</a>
              </div> */}
            </li>
            <li>
              <a href='#testimonials' className='page-scroll' style={{textTransform: 'none'}}>
                Testimonials
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll' style={{textTransform: 'none'}}>
                Contact
              </a>
            </li>
            <li>
              <a href='https://bizdev.cwac.co.in' target="_blank" className='page-scroll' style={{textTransform: 'none'}}>
                BizDev
              </a>
            </li>
            <li>
              <a href='https://support.cwac.co.in' target="_blank" className='page-scroll' style={{textTransform: 'none'}}>
                Support
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{textTransform: 'none'}}>
                Policies
              </a>
              <div class="dropdown-content">
                <a href="https://cwac.co.in/policy/privacy-policy" target="_blank">Privacy Policy</a>
                <a href="https://cwac.co.in/policy/terms-of-service" target="_blank">Terms & Conditions</a>
                <a href="https://cwac.co.in/policy/refund-policy" target="_blank">Refunds / Cancellations</a>
                <a href="https://cwac.co.in/policy/shipping-policy" target="_blank">Shipping policy</a>
                {/* <a href="https://merchant.razorpay.com/policy/KbXp6NFrhWcojt/privacy" target="_blank">Privacy Policy</a>
                <a href="https://merchant.razorpay.com/policy/KbXp6NFrhWcojt/terms" target="_blank">Terms & Conditions</a>
                <a href="https://merchant.razorpay.com/policy/KbXp6NFrhWcojt/refund" target="_blank">Cancellation & Refund Policy</a>
                <a href="https://merchant.razorpay.com/policy/KbXp6NFrhWcojt/shipping" target="_blank">Shipping & Delivery Policy</a>
                <a href="https://merchant.razorpay.com/policy/KbXp6NFrhWcojt/contact_us" target="_blank">Contact Us</a> */}
              </div>
            </li>
            <li>
              <a href='https://cwac.co.in/misc/careers' target="_blank" className='page-scroll' style={{textTransform: 'none'}}>
                Careers
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

import DrawingAutoIcon from "./set_sq.png"
import WebAppsIcon from "./web_apps.png"
import MobileAppsIcon from "./mobile_apps.png"
export const Projects = (props) => {
  var icons = [DrawingAutoIcon, WebAppsIcon, MobileAppsIcon]
  return (
    <div id='projects' className='text-center' style={{backgroundColor: "#ffffff"}}>
      <div style={{backgroundColor: "#ffffff"}}>
        <div className='section-title'>
          <div style={{height: "20px"}}></div>
          <h2>Projects Done</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className='row' style={{marginTop: "-80px", marginLeft: "10px", marginRight: "10px"}}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  {/* <i className={d.icon}></i> */}
                  {/* <img width="48px" height="48px" src={icons[i]} /> */}
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p style={{textAlign: "justify"}}>{d.text} {" "}
                      {d.fileName != "" && <a style={{color: "#ff0000"}} href={d.url} target="_blank" rel="noopener" download={d.fileName}>Read More</a>}
                      {d.fileName == "" && <a style={{color: "#ff0000"}} href={d.url} target="_blank" rel="noopener">Read More</a>}
                    </p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

import Website from "./website.png"
import DrawingAutoIcon from "./set_sq.png"
import WebAppsIcon from "./web_apps.png"
import MobileAppsIcon from "./mobile_apps.png"
export const Services = (props) => {
  var icons = [Website, WebAppsIcon, MobileAppsIcon, DrawingAutoIcon]
  return (
    <div id='services' className='text-center'>
      <div className='container' style={{marginBottom: "-180px"}}>
        <div className='section-title' style={{marginBottom: "-0px", marginTop: "-60px"}}>
          <h2>Services</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className='row' style={{marginBottom: "-20px"}}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  {/* <i className={d.icon}></i> */}
                  <img width="48px" height="48px" src={icons[i]} />
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    {d.name == "Website Development" && <p style={{textAlign: "justify"}}>{d.text}&nbsp;<a href="https://cwac.co.in/misc/wts/websites_list" target="_blank"><b>We have 75+ website designs. Click here to see them all</b></a>&nbsp;OR&nbsp;<a href="https://cwac.co.in/Website%20Catalog.pdf" target="_blank"><b>Download Catalog</b></a>.</p>}
                    {d.name == "Web Apps, Software, Portal Development" && <p style={{textAlign: "justify"}}>{d.text}&nbsp;<a href="https://cwac.co.in/misc/sfs/sf_designs_list" target="_blank"><b>We have 50+ designs. Click here to see them all</b></a>&nbsp;OR&nbsp;<a href="https://cwac.co.in/Web%20Apps%20Catalog.pdf" target="_blank"><b>Download Catalog</b></a>.</p>}
                    {d.name != "Website Development" && d.name != "Web Apps, Software, Portal Development" && <p style={{textAlign: "justify"}}>{d.text}</p>}
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

export const About = (props) => {
  return (
    <div id="about">
      <div className="container" style={{marginBottom: "-30px"}}>
        <div className="row" style={{marginTop: "-40px"}}>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Me</h2>
              {/* <p>{props.data ? "Hi, my name is Rohan. I run this company to help customers improve their business processes by providing high-quality digital solutions. A highly qualified and dedicated team is involved with total 30+ man years of experience. The team is capable to carry out the typical SDLC operations to build a robust solution to service the customers. Each member are not only pioneers in their role but also are excellent in other roles to assist other members and their project stakeholders." : "loading..."}</p> */}
              <p style={{textAlign: "justify"}}>{props.data ? "This is Rohan Vishwakarma. I run this company which provides digital solutions to my company's client. My track record being an expert in developing software modules and solutions, I have a multi-dimensional technical and non-technical approach when it comes to run a company. I have powered my company with strong associations with other companies having strong support teams to strongly and properly process issues and go ahead. My belief is to always prioritize sustainability before scalability." : "loading..."}</p>
              <a href="https://www.linkedin.com/in/rohan-vishwakarma-640157110/" target="_blank"><img style={{width: "60px"}} src={"https://cwac.co.in/linkedin.png"}/></a>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Projects } from "./components/projects";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {Switch, Route, HashRouter as Router } from "react-router-dom";
import { Helmet } from 'react-helmet';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    // Load Google Tag Manager script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CTLTZV6FTX';
    document.head.appendChild(script);

    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // Configure gtag
    gtag('config', 'G-CTLTZV6FTX');
  }, []);

  return (
    <Router>
      <Route path="/:id*">
        <Navigation />
        <Header data={landingPageData.Header} />
        {/* <Features data={landingPageData.Features} /> */}
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Projects data={landingPageData.Projects} />
        {/* <Gallery data={landingPageData.Gallery}/> */}
        <Testimonials data={landingPageData.Testimonials} />
        {/* <Team data={landingPageData.Team} /> */}
        <Contact data={landingPageData.Contact} />
        <Helmet>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/42463384.js"></script>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/42463384.js"></script>
          <meta name="description" content="We provide Website, Software, Android App Development Services, SaaS Products. We deliver client-centric solutions & support them via Online Means" />
        </Helmet>
      </Route>
      {/* <Route path="/">
        <Navigation />
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Projects data={landingPageData.Projects} />
        <Testimonials data={landingPageData.Testimonials} />
        <Contact data={landingPageData.Contact} />
        <Helmet>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/42463384.js"></script>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/42463384.js"></script>
        </Helmet>
      </Route> */}
    </Router>

  );
};

export default App;

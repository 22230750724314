import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
var mLoading = false;
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [loading, SetLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  if (loading && mLoading)
  {
    mLoading = false;
    fetch('https://cwacalb-1699227337.ap-south-1.elb.amazonaws.com:5003/send_cwac_message', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "Name": name,
        "Email": email,
        'Message': message
      })
    })
    .then((res) => res.text())
    .then((res) => {
      alert(res);
      SetLoading(false);
    });
  }
  return (
    <div>
      <div id='contact'>
        <div className='container' style={{marginTop: "-70px", marginBottom: "-50px"}}>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contact Us</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={evt => {
                  // alert(name + ", " + email + ", ")
                  // alert(message);
                  evt.preventDefault();
                  mLoading = true;
                  SetLoading(true);
                  }}>
              {/* <form name='sentMessage' validate> */}
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                {/* <button className='btn btn-custom btn-lg' onClick={evt => alert("Work in progress. You will very soon send messages from here")}> */}
                {!loading && <button className='btn btn-custom btn-lg'>
                  Send Message
                </button>}
                {loading && <p>Submitting Message ...</p>}
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Operating Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12' style={{marginTop: "-20px"}}>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={"https://www.linkedin.com/company/cwac-technologies-private-limited"} target="_blank">
                      {/* <i className='fa fa-linkedin'></i> */}
                      <img style={{width: "60px"}} src={"https://cwac.co.in/linkedin.png"}/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; {new Date().getFullYear()} CWAC Technologies Private Limited
            {/* CWAC Technologies Private Limited, {new Date().getFullYear()} */}
          </p>
        </div>
      </div>
    </div>
  )
}

import DRDOClient from "./drdo_client.png";
import DefenseClient from "./defense_client.png";
import AAEClient from "./aae_client.png";
import SMEClient from "./sme_client.png";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
var testimonialList = [
    <p style={{padding: "10px", userSelect: "none", height: "125px", borderStyle: "outset"}} draggable="false">CWAC Technologies Pvt. Ltd. has satisfactorily completed the supply order "Software for Auto Generation of QAP from 2D Drawing as per specification": <b>VRDE, DRDO</b></p>,
    <p style={{padding: "10px", userSelect: "none", height: "125px", borderStyle: "outset"}} draggable="false">Manufacturing Traceability Software has significantly contributed to our company's manufacturing quality by fetching any deviations and reports of any part from complex assembly structure of our Products: <b>A. A. Engineering Works</b></p>,
    <p style={{padding: "10px", userSelect: "none", height: "125px", borderStyle: "outset"}} draggable="false">QAP Digitization Software has innovatively and immensely contributed in managing Quality Assurance Process of our business by consistently acheiving the required quality standards and eliminating human intervention: <b>Sri Meenakshi Enterprises</b></p>
];
export const Testimonials = (props) => {
  //const clients = [DRDOClient, DefenseClient, AAEClient, SMEClient]
  const clients = [DRDOClient, AAEClient, SMEClient]
  return (
    <div id='testimonials'>
      <div style={{marginLeft: "15px", marginRight: "15px", height: "130px", marginBottom: "140px"}}>
        <div className='section-title text-center' style={{marginTop: "-90px"}}>
          {/* <h2>Our Clients</h2> */}
          <h2>Testimonials</h2>
        </div>
        <div style={{marginTop: "-30px"}}>
        {/* <div style={{backgroundColor: "#000000"}}> */}
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                      {' '}
                      <img width={d.width} height={d.height} src={clients[i]} style={{marginLeft: d.marginLeft}} alt='' />{' '}
                  </div>
                </div>
              ))
            : 'loading'} */}
            <AliceCarousel infinite autoPlay mouseTracking items={testimonialList} responsive={{
              0: {items: 1,},
              1024: {items: 1,itemsFit: 'contain',}
            }}
            autoPlayInterval={2000}
            animationDuration={2000}
            disableDotsControls
            disableButtonsControls
            />
        </div>
      </div>
    </div>
  )
}
